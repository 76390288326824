import { Page } from 'components/Page';

import { ErrorPage } from '@fllite-fe/shared/src/components/ErrorPage';
import { Layout } from '@fllite-fe/shared/src/components/Layout';

const Page404 = () => (
	<Layout>
		<Page>
			<ErrorPage statusCode={404} />
		</Page>
	</Layout>
);

export default Page404;
