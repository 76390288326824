import { FC } from 'react';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Link from 'next/link';

import { useAuthentication } from '../hooks/useAuthentication';
import { StyledButton } from './Button';
import { Text } from './Text';

interface ErrorPageProps {
	statusCode?: number;
}

const Content = styled('div')`
	background: url('/assets/header_bg.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center 50%;
	min-height: calc(100vh - 70px);
	text-align: center;
	padding-top: 20vh;
`;

const Title = styled('div')`
	color: ${({ theme }) => theme.colors.primaryLight};
	font-size: 120px;
	font-weight: 600;
	opacity: 0.5;
	line-height: 1;

	${({ theme }) => theme.breakpoints.up('sm')} {
		font-size: 180px;
	}
`;

const SubTitle = styled('div')`
	color: #fff;
	font-size: 24px;
	font-weight: 600;
`;

const ButtonWrapper = styled('div')`
	max-width: 250px;
	margin: 25px auto 0;
`;

export const ErrorPage: FC<ErrorPageProps> = ({ statusCode }) => {
	const { user } = useAuthentication();

	return (
		<Content>
			{statusCode === 403 ? (
				<Typography fontSize={32} color="white" fontWeight={600}>
					Trip not available
				</Typography>
			) : (
				<>
					<Title>{statusCode ?? 'Error'}</Title>
					<SubTitle>You flew way too high!</SubTitle>
				</>
			)}

			{statusCode === 403 ? (
				<>
					<Typography mt={2} color="white">
						Sorry, this trip is not available.
					</Typography>
					<Typography mt={1} color="white">
						With Fllite you can always request a private flight that will suit your needs
					</Typography>
				</>
			) : (
				<Text>
					{statusCode === 404
						? 'Sorry, this page does not exist'
						: `An error ${statusCode} occurred on server`}
				</Text>
			)}

			<ButtonWrapper>
				<Link href="/" passHref legacyBehavior>
					<StyledButton secondary data-testid="error-page-back" as="a" type={null}>
						{statusCode === 403
							? 'Request a trip'
							: user
								? 'Return to Dashboard'
								: 'Return to Homepage'}
					</StyledButton>
				</Link>
			</ButtonWrapper>
		</Content>
	);
};
